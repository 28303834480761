<template>
  <div>
    <div v-if="successBulkPublished === false">
      <a-form-model @submit.prevent="$emit('submit')" spellcheck="false" ref="form" :rules="rules" :model="form">
        <b-row>
          <b-col cols="8">
            <a-form-model-item
              label="Source Title">
              <a-input :disabled="true" :value="singlePost.title" />
            </a-form-model-item>
          </b-col>
        </b-row>
        <div v-for="(item,index) in formData" :key="index">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="1">
                  <a-form-model-item
                    label="Select">
                    <a-input
                      name="checked"
                      type="checkbox"
                      id="checkbox"
                      v-model="item.checkedBox"
                      :checked="item.checkedBox"
                      @click="handleChecked(index,$event)"
                    />
                  </a-form-model-item>
                </b-col>
                <b-col cols="2">
                  <a-form-model-item
                    label="Portal Name">
                    <a-input :disabled="true" :value="item.portal_name" />
                  </a-form-model-item>
                </b-col>
                <b-col cols="4">
                  <a-form-model-item
                    label="News Title">
                    <a-input :disabled="(item.checkedBox == 'true') ? false : true" v-model="item.news_title" />
                  </a-form-model-item>
                </b-col>
                <b-col cols="3">
                  <a-form-model-item
                  label="Default Category">
                    <a-select v-model="item.category_id">
                      <a-select-option v-for="(category,indexCategory) in getCategories(item.portal_id)" :key="`category-${indexCategory}`" :value="category.id"> {{ category.title }} </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div class="text-left">
            <b-button variant="primary" @click="submit()" :disabled="bulkPublishedSubmitButton">
              Bulk Published
            </b-button>
        </div>
      </a-form-model>
    </div>
    <div v-if="successBulkPublished === true">
      <table class="table table-striped table-inverse table-responsive">
          <thead class="thead-inverse">
          <tr>
            <th>#</th>
            <th>News Title</th>
            <th>Link</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in successBulkPublishedData" :key="index">
              <td class="tdauthor">
                {{ index + 1 }}
              </td>
              <td class="tdauthor">
                {{ item.title }}
              </td>
              <td scope="row">
                <a
                  :href="item.url"
                  target="_blank"
                >
                {{ item.url }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      rules: {
        title: [{ required: true, message: 'Please insert title' }],
        portal_id: [{ required: true, message: 'Please select portal' }],
        category_id: [{ required: true, message: 'Please select category' }],
      },
      formData: [],
      bulkPublishedData: [],
      singlePost: [],
      bulkPublishedSubmitButton: false,
      successBulkPublished: false,
      successBulkPublishedData: [],
    }
  },
  mounted() {
    this.$nprogress.start()
    const vm = this
    vm.$store.dispatch('portal/BULK_PUBLISHED').then((res) => {
      this.$nprogress.done()
      const dataBulk = res.data.data
      this.bulkPublishedData = dataBulk
      for (let index = 0; index < dataBulk.length; index++) {
        const { id, title, categories } = dataBulk[index]
        this.formData.push({
          checkedBox: 'true',
          news_title: '',
          portal_id: id,
          portal_name: title,
          ccc: '',
          category_id: categories[0].id,
        })
      }
    }).catch(() => {
      this.$nprogress.done()
    })

    vm.$store.dispatch('post/FETCH_ONE', vm.$route.params.id).then((res) => {
      vm.singlePost = res.data
    }).catch(() => {
      this.$nprogress.done()
    })
  },
  methods: {
    submit() {
      const vm = this
      vm.bulkPublishedSubmitButton = true
      let form_data = vm.formData
      form_data = form_data.filter((data) => data.checkedBox === 'true')
      const payload = {
        source_post_id: vm.$route.params.id,
        bulk_published: form_data,
      }
      let title_map = form_data.map((data) => data.news_title)
      title_map = title_map.filter((results) => results !== '')
      const toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
      const duplicateElements = toFindDuplicates(title_map)
      if (duplicateElements.length > 0) {
        vm.$notification.error({
          message: `News Title is Duplicated : ${duplicateElements[0]}`,
          description: 'Duplicate Title',
        })
        vm.bulkPublishedSubmitButton = false
      } else {
        vm.$store.dispatch('post/INSERT_BULK_PUBLISHED', payload).then((res) => {
          if (res.data.status) {
            vm.$notification.success({
              message: res.data.message,
              description: 'Successfully created',
            })
            for (let index = 0; index < form_data.length; index++) {
              form_data[index].news_title = ''
            }
            vm.successBulkPublishedData = res.data.data
            vm.successBulkPublished = true
            vm.bulkPublishedSubmitButton = false
          } else {
            const duplicated_title = res.data.errors
            for (let index = 0; index < duplicated_title.length; index++) {
              const title = duplicated_title[index]
              vm.$notification.error({
                message: `News Title is Duplicated : ${title}`,
                description: 'Duplicate Title',
              })
            }
            vm.bulkPublishedSubmitButton = false
          }
        }).catch(() => {
          this.$nprogress.done()
        })
      }
    },
    handleChecked(index, event) {
      const { checked } = event.target
      this.formData[index].checkedBox = checked
    },
    getTitle(id) {
      const entry = this.bulkPublishedData.find((item) => item.id === id)
      return entry ? entry.title : ''
    },
    getCategories(id) {
      const entry = this.bulkPublishedData.find((item) => item.id === id)
      return entry ? entry.categories : []
    },
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
  },
}
</script>

<style lang="scss">
@import "./style.module.scss";
</style>
